import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import "@fontsource/poppins/100.css";
import Scroll from '../components/scroll';
import { Helmet } from "react-helmet";
import i18next from "i18next";

const DeletePage = () => {

    const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
    const [language, setLanguage] = useState('en');  // default language
  
    useEffect(() => {
      if (typeof localStorage !== 'undefined') {
        setLocalStorageAvailable(true);
        const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
        setLanguage(lang);
      }
    }, []);
    
    return (
      <div className="">
        <Helmet htmlAttributes={{ lang: language }}>
          <meta charSet="utf-8" />
          <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
          <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
          <meta name="author" content="Rejoice" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{i18next.t("about.header.title")}</title>
        </Helmet>
        <Header />
        <Scroll showBelow={250} />
        <div className="text-center" id="aboutDesktop">
          <h2 className="dmsans mt-12 pinkperfect text-3xl font-normal">{i18next.t("delete.title")}</h2>
          <p className="w-3/4 lg:w-1/2 md:text-center text-justify mt-4 mx-auto leading-10">{i18next.t("delete.text.p1")}</p>
          <p className="w-3/4 lg:w-1/2 md:text-center text-justify mt-4 mx-auto leading-10">{i18next.t("delete.text.p2")}</p><a href="mailto:contact@rejoiceapp.io" className="text-indigo-900 font-bold">contact@rejoiceapp.io</a>
          <p className="w-3/4 lg:w-1/2 md:text-center text-justify mt-4 mx-auto leading-10">{i18next.t("delete.text.p3")}</p>
        </div>
        <div className="mr-12 mt-12 md:mt-24 ml-12 relative mx-auto lg:flex hidden justify-center flex-wrap flex-row">
          
        </div>
        <Footer />
      </div>
    );
  };
  
  export default DeletePage;